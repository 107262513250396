<template>
  <div class="home">
    <Header/>
    <first-content/>
    <second-content/>
    <img-list/>
    <Footer/>
    <Tootip/>
  </div>
</template>

<script>
import Header from '../../components/Dashboard/Header.vue'
import firstContent from '../../components/Dashboard/content1.vue'
import secondContent from '../../components/Dashboard/content2.vue'
import imgList from '../../components/Dashboard/ImgList.vue'
import Footer from '../../components/Footer/Footer.vue'
import Tootip from '../../components/Dashboard/tootip.vue'
export default {
  components:{Header,firstContent,secondContent,imgList,Footer,Tootip},
  data() {
    return {
    }
  },

  methods: {
    //登录
  },
}
</script>

<style lang="less" scoped>
.home {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  min-width: 1300px;
}


</style>
