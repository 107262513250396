<template>
  <div class="header">
    <headerTitle class="headerTop" />
    <div class="header-content">
      <div class="header-content_btn" >
        <div class="text">感受数据魅力</div>
        <div class="text" style="margin-left: 130px;margin-top:12px">展现无限可能</div>
        <div class="header-content_btn_text">
          <div @click="jumpTo('display')" class="header-content_btn_text_left">在线演示</div>
          <div @click="jumpTo('download')" class="header-content_btn_text_right">源码下载</div>
        </div>
      </div>
      <img class="header-content_bg"  src="../../assets/img/dashboard/仪表盘设计器1.png" />
    </div>
  </div>
</template>
<script>
import headerTitle from '../Header/dashBoardHeader.vue'
export default {
  data() {
    return {
    }
  },
  components:{
    headerTitle
  },
  methods: {
    open(val){
      if(val=='b'){
        window.open('http://gccloud.com/','_blank')
      }else if(val=='a'){
        this.$router.push({ path: '/introduce' })
      }
    },
    jumpTo(val){
      if(val==='display'){
        window.open('http://gcpaas.gccloud.com/dashboard','_blank')
      }else if(val==='download'){
        window.open('https://gitee.com/gcpaas/DashBoard','_blank')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-size: 100%;
  position: relative;
  .headerTop{
    position: fixed;
    top: 0;
    right: 0;
  }
  &-content {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: url('~@/assets/img/dashboard/bg1.png') no-repeat;
    // padding: -100px 0 0 15vw ;
    padding-right: 8vw;
    min-height: 450px;
    &_btn{
      color: #171A1D;
      // font-weight: bolder;
      font-size: 50px;
      line-height: normal;
      letter-spacing: 1px;
      position: relative;
      text-align: left;
      margin-left: 12vw;
      .text{
        white-space: nowrap;
      }
      &_text{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #FFFFFF;
        font-size: 28px;
        line-height: normal;
        margin-top: 56px;
        &_left{
          color: #007aff;
          width: 190px;
          height: 56px;
          line-height: 56px;
          text-align: center;
          cursor: pointer;
          border-radius: 105px;
          border: 2px solid #007AFF;
          &:hover{
            box-shadow: 0 5px 8px rgba(0,122,255,.5);
          }
        }
        &_right{
           color: #FFFFFF;
           width: 190px;
           height: 56px;
           line-height: 56px;
           text-align: center;
           cursor: pointer;
           border-radius: 105px;
           background: #007AFF;
           &:hover{
            box-shadow: 0 5px 8px rgba(0,122,255,.5);
          }
        }
      }

    }
    &_bg{
      // transform:scale();
      max-width: 550px;
      max-height: 450px;
      overflow: hidden;
    }
  }
}

/deep/.el-dropdown{
  color: #171A1D;
  font-size: 16px;
  cursor: pointer;
  &:hover{
  color: #171A1DA8;
  }
}

/deep/ .el-dropdown-menu{
  top:50px !important
}

/deep/ .el-popper{
  top:50px !important
}
</style>
