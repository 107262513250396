<template>
    <div class="contact">
        <el-tooltip effect="light"  placement="left">
            <div class="item" style="border-bottom:1px solid #A6A6A6" v-popover:phonePopover><img src="../../assets/img/mailicon.png"></div>
            <div slot="content">
              <div class="title">联系邮箱</div>
              <div class="text">tech@ustcinfo.com</div>
            </div>
        </el-tooltip>
        <el-tooltip effect="light" placement="left">
          <div class="item"><img @click="join"  src="../../assets/img/QQicon.png"></div>
           <div slot="content">
              <div class="title">QQ群</div>
              <div class="text">322302395（已满）</div>
              <div class="text">631457306</div>
            </div>
        </el-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'tootip',
        data() {
            return {}
        },
        methods: {
          join(){
            window.open("https://qm.qq.com/q/dCsca8663u",'_ablank')
          }
        }
    }
</script>

<style lang="less" scoped>
  .contact {
    position: fixed;
    right: 2%;
    bottom: 20%;
    z-index: 1000;
    border-radius: 46px;
    background: #E4E4E4EA;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 16px 32px 4px #A1B2DC26;
    .item {
      border-radius: 2px;
      cursor: pointer;
      margin: 5px;
      padding: 5px;
      text-align: center;
      
      img {
        height: 30px;
      }
    }
  }
    .title{
        color: #171A1D;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0px;
        font-weight: bolder;
        margin-bottom: 10px;
      }
      .text{
        color: #171a1d85;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0px;
      }

</style>
