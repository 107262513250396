<template>
  <div class="content">
    <img class="content_bg" style="z-index:1" src="../../assets/img//dashboard/仪表盘设计器2.png" alt="">
    <img class="imgBg" src="../../assets/img/dashboard/bg2.png" alt="">
    <div class="content-list">
      <div class="content-list_title">图形化设计</div>
      <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>可视化设计</div>
        <div class="content-list_item_content">强⼤的拖拽、缩放、适配能⼒</div>
      </div>
     <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>丰富图组件</div>
        <div class="content-list_item_content">丰富的图表设计，⽀持40+组件</div>
      </div>
      <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>移动端适配</div>
        <div class="content-list_item_content">⽀持H5、Android、IOS移动端</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
   width: 100%;
   height:600px;
   position: relative;
  //  z-index: 99;
   flex-direction: row;
   padding: 0 100px 0 100px;
   background-size: cover;
   align-items: center;
   justify-content: space-around;
   &_bg{
      max-width: 600px;
      overflow: hidden;
    }
   .imgBg{
    position: absolute;
    width: 100%;
    height: 550px;
    bottom: 0;
    z-index: 0;
   }
   &-list {
    z-index: 1;
    margin-left: 54px;
    &_title{ 
      color: #171A1D;
      font-weight: bolder;
      font-size: 32px;
      line-height: normal;
      margin-bottom: 40px;
    }
    &_item{ 
      &_content{
          color: #171A1DA8;
          font-size: 20px;
          line-height: 36px;
          letter-spacing: 0px;
          margin:0 34px 16px 0;
        }
      &_title{
       color: #171A1D;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;
        .dot {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #007aff;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
          margin-right:22px;
        }
      }
    }
   }
}
</style>
