<template>
  <div class="header-title">
    <!-- <img style="cursor: pointer" @click="jumpTo('home')" src="../../assets/img/dashboard/dashboardLogo.png" /> -->
    <div @click="jumpTo('home')" class="header-title-left">
      <img class="img"  src="../../assets/img/dashboardLogo.svg" />
      <span class="logoText">GCPAAS</span>
    </div>
    <div class="header-title_tab">
        <div @click="jumpTo('home')" class="header-title_tab_text">大屏设计器</div>
        <div @click="jumpTo('dashboard')" class="header-title_tab_text">仪表盘设计器</div>
        <div class="header-title_tab_text">
          <el-dropdown placement='bottom' trigger="click" @command="jump">
            <span style="font-size:16px">
              在线文档<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="bigscreen">大屏设计器手册</el-dropdown-item>
              <el-dropdown-item command="dashboard">仪表盘设计器手册</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="header-title_tab_text">
          <el-dropdown placement='bottom' trigger="click" @command="jump">
            <span style="font-size:16px">
              源码下载<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="github">GitHub</el-dropdown-item>
              <el-dropdown-item command="gitee">Gitee 码云</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="header-title_tab_text">
          <el-dropdown placement='bottom' trigger="click" @command="open">
            <span style="font-size:16px">
              联系我们<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">关于我们</el-dropdown-item>
              <el-dropdown-item command="b">公司主页</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    open(val){
      if(val=='b'){
        window.open('http://gccloud.com/','_blank')
      }else if(val=='a'){
        this.$router.push({ path: '/introduce' })
      }
    },
    jump(val){
      if(val=='bigscreen'){
       window.open('https://www.yuque.com/chuinixiongkou/bigscreen/index','_blank')
      }else if(val=='dashboard'){
        window.open('https://www.yuque.com/chuinixiongkou/dashboard/index','_blank')
      }else if(val=='github'){
         window.open('https://github.com/gcpaas','_blank')
      }else if(val=='gitee'){
        window.open('https://gitee.com/gcpaas','_blank')
      }
    },
    jumpTo(val){
      if(val==='display'){
        window.open('http://gcpaas.gccloud.com/bigScreen','_blank')
      }else if(val==='download'){
        window.open('https://github.com/gcpaas','_blank')
      }else if(val==='home'){
        this.$router.push({ path: '/' })
      }else if(val==='dashboard'){
        this.$router.push({ path: '/dashboard' })
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .header-title {
    z-index: 100;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding:0 15vw;
    justify-content: space-between;
    background-color: #ffff;
    &-left{
      height: 80px;
      line-height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .img{
        height: 30px;
        width: 30px;
      }
      .logoText{
        font-weight: bolder;
        font-size: 20px;
        margin-left: 8px;
        color: #203d86;
      }
    }
    &_tab {
      color: #171A1D;
      font-size: 16px;
      line-height: normal;
      display: flex;
      &_text {
        white-space: nowrap;
        height: 80px;
        line-height:80px;
        padding: 0 28px;
        cursor: pointer;
        color: #171A1D;
        &:hover{
        color: #171A1DA8;
        }
      }
    }
  }

/deep/.el-dropdown{
  color: #171A1D;
  font-size: 16px;
  cursor: pointer;
  &:hover{
  color: #171A1DA8;
  }
}

/deep/ .el-dropdown-menu{
  top:50px !important
}

/deep/ .el-popper{
  top:50px !important
}
</style>
